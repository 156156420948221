export const API_ENDPOINTS = {
  ACCOUNT: "Account",
  DINING_AREAS: "DiningAreas",
  FACILITIES: "Facilities",
  MEALS: "Meals",
  MENUS: "Menus",
  ORDERS: "Orders",
  RESIDENTS: "Residents",
  ROLES: "Roles",
  SYSTEM_MESSAGES: "SystemMessages",
  TABLESIDE_AUTOMATION: "TableSideAutomation",
  USERS: "UserLogin",
  USER_CREDENTIALS: "UserCredentials",
  USER_FACILITIES: "UserFacilities",
  USER_ROLES: "UserRole",
};

export const GUEST_TYPES = ["None", "Guest", "Employee", "Other"]
export const PAYMENT_TYPES = ["No Charge", "Payment Received", "Bill to Resident", "Bill to Employee"];
export const DEFAULT_EMPTY = ["", "N/A", null, undefined];
//Date options object for direct print formatting, used in Held Orders, Cook's Ticket, and Orders view currently
export const DIRECT_PRINT_DATE_OPTIONS = { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };


