import BaseDataGrid from "components/shared/baseDataGrid/BaseDataGrid";
import React, { useState } from "react";
import { useGetDiningAreasQuery } from "store/apis/DiningAreasApi";
import { useAddResidentOrderMutation, useDeleteHeldOrderMutation } from "store/apis/OrdersApi";
import PencilIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setGuestInfo, setMenuInfo } from "store/slices/orderSlice/OrderSlice";
import DeleteModal from "components/shared/deleteModal/DeleteModal";
import { CustomNoRowsOverlay } from "components/admin/admin.overlays";
import { useGetResidentsAllQuery } from "store/apis/ResidentsApi";
import { DEFAULT_EMPTY, DIRECT_PRINT_DATE_OPTIONS, PAYMENT_TYPES } from "store/store.constants";

//Cannot use CSS className for print output, must use inline styles
const printStyles = {
  printGeneral: {
    display: 'flex',
    paddingBottom: '0px',
    paddingTop: '0px'
  },
  printDiv: {
    paddingBottom: '0px',
    paddingTop: '0px'
  },
}

export function TotalRowsFooterComponent(props) {
  return (
    <Box sx={{p: 2, display: 'flex', borderTop: "1px solid lightgray", justifyContent: "flex-end"}}>
      Total Rows: {props?.ordersData?.length || 0}
    </Box>
  );
}

export default function HeldOrders({heldOrders, currentUserData, isDirectPrint, handlePrint}) {
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedForDeleteId, setSelectedForDeleteId] = useState(null);

  const {
    data: diningAreas,
    isFetching: fetchingAreas,
    isError: areasError
  } = useGetDiningAreasQuery();

  const {
    data: residents,
    isFetching: fetchingResidents,
  } = useGetResidentsAllQuery();

  const [
    submitHeldOrder
  ] = useAddResidentOrderMutation();

  const [
    deleteHeldOrder
  ] = useDeleteHeldOrderMutation();

  const submitCheckedOrders = () => {
    if (isDirectPrint) {
      const html = document.getElementById('printSection').cloneNode(true);
      handlePrint(html);
    }

    selectedOrderIds?.forEach(async (orderId) => {
      const orderToSubmit = heldOrders?.find((order) => order?.order?.id === orderId);

      if (orderToSubmit) {
        await submitHeldOrder(orderToSubmit).then((res) => {
          if (res?.data?.success) {
            deleteHeldOrder(orderToSubmit?.order?.id);
          }
        });
      }
    });

  }

  const handleEditOrder = (rowData) => {
    const residentId = rowData.order.residentId;
    const tsSetup = rowData.order.tableSideSetupId;

    dispatch(setMenuInfo(rowData));
    if (rowData?.order?.guest?.length) {
      dispatch(setGuestInfo(
        {
          guestName: rowData?.order?.guest,
          paymentDone: rowData?.order?.guestBillingTypeId,
          selectedResident: rowData?.order?.guestOfResidentId || 0,
          selectedGuestType: rowData?.order?.guestTypeId 
        }
      ));

      navigate({
        pathname: `/takeorder/guest/order`,
        search: '?from=held'
      });
    } else {
      navigate({
        pathname: `/takeorder/residents/order/${residentId}/${tsSetup}`,
        search: "?current=true&from=held"
      });
    }
  }

  const handleDeleteOrder = (rowData) => {
    setSelectedForDeleteId(rowData?.order?.id);
    setShowDeleteModal(true);
  }

  const heldOrderColumns = [
    { 
      field: "residentName", 
      headerName: "Full Name", 
      flex: 2,
      height: "auto",
      valueGetter: (value, row) => {
        return row?.residentName?.trim() || row?.order?.guest;
      }
    },
    { 
      field: "order.diningAreaId", 
      headerName: "Dining Area", 
      flex: 2,
      valueGetter: (value, row) => { 
        return diningAreas?.find((area) => area.id === row?.order?.diningAreaId)?.name;
      }
    },
    { 
      field: "tableName", 
      headerName: "Table Name", 
      flex: 2,
      valueGetter: (value, row) => {
        return row?.order?.tableName;
      }
    },
    { 
      field: "actions", 
      sortable: false,
      disableColumnMenu: true,
      headerName: "Actions", 
      flex: 2,
      renderCell: (params) => {
        return (
          <div style={{display: "flex", flex: "0 0 auto", alignItems: "center", justifyContent: "center", maxHeight: "1.375rem"}}>
            <IconButton
              sx={{color: "gray", marginRight: ".25rem"}}
              aria-label="Delete"
              onClick={() => handleDeleteOrder(params.row)}
            >
              <DeleteIcon sx={{alignSelf: "center"}} />
            </IconButton>
            <IconButton 
              sx={{color: "gray", marginRight: ".25rem"}} 
              aria-label="Edit"
              onClick={() => handleEditOrder(params.row)}
              >
              <PencilIcon />
            </IconButton>
          </div>
        )
      }
    }
  ];

  const getResidentNameById = (residentId) => {
    return residents?.find((resident) => resident.id === residentId)?.name?.split("(")?.[0]?.trim() || "";
  }

  const getResidentWingById = (residentId) => {
    return residents?.find((resident) => resident.id === residentId)?.wing || "";
  }

  const getDiningAreaNameById = (diningAreaId) => {
    return diningAreas?.find((area) => area.id === diningAreaId)?.name?.trim() || "";
  }

  const renderPrintItems = (heldOrder) => {
    const menuList = [];

    heldOrder?.menuItems?.forEach((item) => {
      const quantity = item.quantity.toFixed(2);

      menuList.push(
        <div key={item.itemId} style={printStyles.printDiv}>
          <span>{quantity} - {item.portionSize || ''} <b>{item.name}</b></span>
        </div>
      );

      if (item?.specialNote) {
        menuList.push(
          <div key={`${item.itemId}-specialNote`} style={{paddingY: "0px", paddingLeft: "24px"}}>
            <span><b>{`Special Note: ${item.specialNote}`}</b></span>
          </div>
        );
      }

      item.subItemList?.forEach((sub) => {
        menuList.push(
          <div key={sub.recipeId} style={{paddingY: "0px", paddingLeft: "24px"}}>
          <span><b>{`Add - ${sub.recipeName} (${sub.quantity})`}</b></span>
        </div>
        )
      });
    });

    heldOrder?.alternateItems?.forEach((alt) => {
      const quantity = alt.quantity.toFixed(2);

      menuList.push(
        <div key={alt.itemId} style={printStyles.printDiv}>
          <span>{quantity} - {alt.portionSize || ''} <b>{alt.name}</b></span>
        </div>
      );

      if (alt?.specialNote) {
        menuList.push(
          <div key={`${alt.itemId}-specialNote`} style={{paddingY: "0px", paddingLeft: "24px"}}>
            <span><b>{`Special Note: ${alt.specialNote}`}</b></span>
          </div>
        );
      }

      alt.subItemList?.forEach((sub) => {
        menuList.push(
          <div key={sub.recipeId} style={{paddingY: "0px", paddingLeft: "24px"}}>
          <span><b>{`Add - ${sub.recipeName} (${sub.quantity})`}</b></span>
        </div>
        )
      });
    });

    return menuList;
  }

  const renderPrintContent = () => {
    const selectedHeldOrders = heldOrders?.filter((held) => selectedOrderIds?.includes(held?.order?.id));
    const printOrderList = [];
    
    selectedHeldOrders?.forEach((heldOrder) => {
      const guestResidentName = getResidentNameById(heldOrder?.order?.guestOfResidentId);
      const residentWing = getResidentWingById(heldOrder?.order?.residentId);
      const diningAreaName = getDiningAreaNameById(heldOrder?.order?.diningAreaId);
      const isIsolation = residents.find((res) => res.id == heldOrder?.order?.residentId)?.isIsolation;
      printOrderList.push(
        <div key={heldOrder?.order?.id} style={{padding: "10px 5px 5px 5px", fontSize: "15px", maxWidth: "300px"}}>
            <div style={{fontSize: "1.25rem", fontWeight: "bold", paddingY: "5px"}}>
              { heldOrder.order.residentId === 0 ? heldOrder.order.guest : heldOrder.residentName }
            </div> 
            {isIsolation && <div style={{fontWeight: "bold", paddingY: "5px"}}>
              * ISOLATION *
            </div>
            } 
            {guestResidentName !== "" &&
              <div style={printStyles.printDiv, {fontSize: ".75rem"}}>
                Guest of: {guestResidentName}
              </div>
            }
            {heldOrder?.order?.residentId === 0 &&
              <div style={printStyles.printDiv, {fontSize: ".75rem"}}>
                Payment Status: {PAYMENT_TYPES[heldOrder?.order?.guestBillingTypeId]}
              </div>
            }
            {diningAreaName !== "" && 
              <div style={printStyles.printGeneral}>
                <span style={{marginRight: ".75rem", fontWeight: "bold", fontSize: ".875rem"}}>
                  {diningAreaName.replace(/Dining Room/gi, 'DR')}
                </span>
                <span style={{fontWeight: "bold", fontSize: ".875rem"}}>
                  {heldOrder.order.tableName ? `Table: ${heldOrder.order.tableName}` : null}
                </span>
              </div>
            }
            {!DEFAULT_EMPTY.includes(heldOrder.roomBed) &&
              <div style={printStyles.printGeneral}>
                <span style={{fontSize: ".75rem", marginBottom: ".5rem"}}>
                  {`Wing: ${residentWing || 'N/A'}`}
                </span>
                <span style={{margin: "0 .5rem", fontSize: ".75rem"}}>|</span>
                <span style={{fontSize: ".75rem"}}>
                  {`Room: ${heldOrder.roomBed}`}
                </span>
              </div>
            }
            <div style={{display: "flex", fontSize: ".675rem"}}>
              <div id="label-col" style={{minWidth: "75px"}}>
                {!DEFAULT_EMPTY.includes(heldOrder.dietName) && <div>Diet:</div> }
                {!DEFAULT_EMPTY.includes(heldOrder.dietTextureName) && <div>Texture:</div> }
                {!DEFAULT_EMPTY.includes(heldOrder.dietOther) && <div>Other:</div> }
                {!DEFAULT_EMPTY.includes(heldOrder.liquidConsistency) && <div>Liquid:</div> }
                {!DEFAULT_EMPTY.includes(heldOrder.allergies) && <div><i>Allergies:</i></div> }
              </div>
              <div id="value-col" style={{whiteSpace: "pre"}}>
                {!DEFAULT_EMPTY.includes(heldOrder.dietName) && <div><b>{heldOrder.dietName}</b></div>}
                {!DEFAULT_EMPTY.includes(heldOrder.dietTextureName) && <div><b>{heldOrder.dietTextureName}</b></div>}
                {!DEFAULT_EMPTY.includes(heldOrder.dietOther) && <div><b>{heldOrder.dietOther}</b></div>}
                {!DEFAULT_EMPTY.includes(heldOrder.liquidConsistency) && <div><b>{heldOrder.liquidConsistency}</b></div>}
                {!DEFAULT_EMPTY.includes(heldOrder.allergies) && <div><i>{heldOrder.allergies}</i></div>}
              </div>
            </div>

            <div style={{marginTop: "1rem", fontSize: ".75rem", marginBottom: "1rem"}}>
              { renderPrintItems(heldOrder) }
              <div style={printStyles.printGeneral}>
                  <i>Added Notes: {heldOrder.order.note || ""}</i>
                </div>
            </div>

            <div style={{marginBottom: "1.5rem", fontSize: ".75rem"}}>
              {!!heldOrder.notes?.length &&
                <div style={printStyles.printGeneral}>
                  MealCard Notes: {heldOrder.notes.join(', ')}
                </div>
              }
              {!DEFAULT_EMPTY.includes(heldOrder.adaptiveEquipment) &&
                <div style={printStyles.printGeneral}>
                  Adaptive Equip: {heldOrder.adaptiveEquipment}
                </div>
              }
              {!DEFAULT_EMPTY.includes(heldOrder.fluidRestriction) &&
                <div style={printStyles.printGeneral}>
                  Fluid Restrict: {heldOrder.fluidRestriction}
                </div>
              }
            </div>

            <div style={{fontSize: ".75rem"}}>
              <div>
                <span><b>Total Price:</b> ${heldOrder.order?.guestPrice?.toFixed(2)}</span>
              </div>
              <div>
                <span><b>Signature: __________________________________</b></span>
              </div>
            </div>
            <div style={{fontSize: "1.25rem", fontWeight: "bold", textAlign: "center"}}>
              { heldOrder.order.residentId === 0 ? heldOrder.order.guest : heldOrder.residentName }
            </div> 
          <div style={{marginTop: "5px", paddingY: "5px", fontSize: "10px"}}>
            {`Order Taken: ${new Date().toLocaleString('en-US', DIRECT_PRINT_DATE_OPTIONS)}, by ${currentUserData?.firstName || ""} ${currentUserData?.lastName || ""}`}
          </div>
          {selectedHeldOrders.indexOf(heldOrder) !== selectedHeldOrders.length - 1 &&
            <p style={{pageBreakBefore: 'always'}}></p>
          }
        </div>
      );
    });

    return printOrderList;
  }

  return (
    <div className="paperContent customScrollbar" style={{backgroundColor: "#FFF"}}>
      <BaseDataGrid
        rows={heldOrders || []}
        columns={heldOrderColumns}
        loading={fetchingAreas || fetchingResidents}
        error={areasError}
        height={selectedOrderIds?.length ? "90%" : "100%"}
        disableRowSelectionOnClick={false}
        disableSelectionOnClick={false}
        width={"100%"}
        pagination={false}
        getRowId={(row) => row?.order?.id}
        EmptyRowMessage={"No Held Orders Found"}
        density={"compact"}
        autoHeight={false}
        checkboxSelection
        onRowSelectionModelChange={(newRows) => {
          setSelectedOrderIds(newRows);
        }}
        rowSelectionModel={selectedOrderIds}
        slots={{
          footer: TotalRowsFooterComponent,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          footer: {ordersData: heldOrders}
        }}
        sx={{
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
            py: "4px",
            lineHeight: "unset",
          },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "15px",
            lineHeight: "unset",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "22px",
            lineHeight: "unset",
          },
        }}
      >
      </BaseDataGrid>
      {
        !!selectedOrderIds?.length && 
        <Button
          variant="contained"
          color="primary"
          sx={{marginTop: ".75rem", fontWeight: "bold"}}
          onClick={() => submitCheckedOrders()}
        >
          SUBMIT TO KITCHEN
        </Button>
      }
      {showDeleteModal && <DeleteModal 
        id={selectedForDeleteId}
        entityName={"this order"}
        apiPath={"deleteHeldOrder"}
        open={showDeleteModal}
        isafterSuccessFunction={true}
        afterSuccessFunction={() => {
          setShowDeleteModal(false);
          setSelectedForDeleteId(null);
        }}
        close={() => { 
          setShowDeleteModal(false); 
          setSelectedForDeleteId(null); 
        }}
      />}
      <div id="printSection" style={{position: "absolute", top: "-9999px"}}>
        {!!selectedOrderIds?.length && renderPrintContent()}
      </div>
    </div>
  );
}