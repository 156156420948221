import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { 
  ORDERS,
  RESIDENTS,
  MEALS
} = API_ENDPOINTS;

export const OrdersApi = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getHeldOrders: builder.query({
      query: () => `/${ORDERS}/HeldOrders`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
        ? [
          ...response.map(({ id }) => ({
            type: ORDERS,
            id,
          })),
          ORDERS
        ]
        : [ORDERS]
      }
    }),
    deleteHeldOrder: builder.mutation({
      query: (orderId) => ({
        url: `/${ORDERS}/ResidentHoldOrder/${orderId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [ORDERS, RESIDENTS]
    }),
    getSubmittedOrders: builder.query({
      query: () => `/${ORDERS}/KitchenSideSetupOrders`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
        ? [
          ...response.map(({ id }) => ({
            type: ORDERS,
            id,
          })),
          ORDERS
        ]
        : [ORDERS]
      }
    }),
    getSubmittedOrderById: builder.query({
      query: (orderId) => `/${ORDERS}/SubmittedOrder/${orderId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
        ? [
          {type: ORDERS, id: response},
          ORDERS
        ]
        : [ORDERS];
      }
    }),
    getFutureOrder: builder.query({
      query: (orderId) => `${ORDERS}/FutureOrders/${orderId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response ?
        [
          {type: ORDERS, id: response.order.id}
        ]
        : [ORDERS];
      }
    }),
    deleteFutureOrder: builder.mutation({
      query: (orderId) => ({
        url: `${ORDERS}/Order/${orderId}`,
        method: "DELETE",
      }),
      invalidatesTags: [ORDERS]
    }),
    reprintOrder: builder.mutation({
      query: (orderId) => ({
        url: `${ORDERS}/ReprintOrder?orderId=${orderId}`,
        method: "POST"
      }),
    }),
    printSummary: builder.mutation({
      query: (orderId) => ({
        url: `${ORDERS}/PrintSummary?orderId=${orderId}`,
        method: "POST"
      }),
    }),
    addCooksTicket: builder.mutation({
      query: (data) => ({url: `${ORDERS}/CooksTicket`, method: "POST", body: data}),
      invalidatesTags: [ORDERS]
    }),
    addResidentOrder: builder.mutation({
      query: (orderData) => ({url: `${ORDERS}/Order`, method: "POST", body: orderData}),
      invalidatesTags: [ORDERS, RESIDENTS, MEALS]
    }),
    addHeldOrder: builder.mutation({
      query: (orderData) => ({url: `${ORDERS}/ResidentHoldOrder`, method: "POST", body: orderData}),
      invalidatesTags: [ORDERS, RESIDENTS]
    })
  })
});

export const {
  useGetHeldOrdersQuery,
  useDeleteHeldOrderMutation,
  useGetSubmittedOrdersQuery,
  useGetSubmittedOrderByIdQuery,
  useGetFutureOrderQuery,
  useDeleteFutureOrderMutation,
  useReprintOrderMutation,
  usePrintSummaryMutation,
  useAddCooksTicketMutation,
  useAddResidentOrderMutation,
  useAddHeldOrderMutation
} = OrdersApi;