

import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { TABLESIDE_AUTOMATION } = API_ENDPOINTS;

export const AutomationAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getFacilityTableSideAutomation: builder.query({
      query: (facilityId) => ({
        url: `/${TABLESIDE_AUTOMATION}?facilityId=${facilityId}`,
      }),
      providesTags: [TABLESIDE_AUTOMATION],
      transformResponse: (response) => response?.data,
    }),
    updateFacilityTableSideAutomation: builder.mutation({
      query: (data) => ({
        url: `/${TABLESIDE_AUTOMATION}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [TABLESIDE_AUTOMATION],
    }),
  })
});

export const {
  useGetFacilityTableSideAutomationQuery,
  useUpdateFacilityTableSideAutomationMutation
} = AutomationAPIs;