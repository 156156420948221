import { Box, Button, Dialog, Slide, Typography } from "@mui/material";
import React, { forwardRef } from "react";
import BaseInput from "../baseForm/BaseInput";
import { useForm } from "react-hook-form";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReasonModal({ title, open, handleClose }) {

  const methods = useForm({
    shouldUnregister: false,
    mode: "all"
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    handleClose(true, data?.reason);
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      TransitionComponent={Transition}
      >
      <Box sx={{width: "450px"}}>
        <Box component="div" flexWrap="wrap" sx={{display: "flex", flex: "1 0 100%", justifyContent: "center"}}>
          <Typography variant="h6" textAlign="center" fontSize="1.5rem" fontWeight="bold" sx={{ margin: "0 0 .5rem", display: "block", width: "100%", backgroundColor: "#e5ebec"}}>
              {title ?? ""}
          </Typography>
          <Box sx={{padding: "0 1rem", width: "100%"}}>
            <BaseInput 
              control={control}
              errors={errors}
              id="reason"
              name="reason"
              label="Reasoning"
              multiline
              variant="outlined"
              validationProps={{
                required: "Reasoning is required."
              }}
              rows="2"
              fullWidth
              required
              margin="normal"
            />
          </Box>
          <Box component="div" sx={{display: "flex", justifyContent: "space-between", width: "100%", padding: "0 .5rem", margin: ".5rem"}}>
            <Button variant="contained" sx={{backgroundColor: "#febe10", color: "black !important"}} onClick={() => handleClose(false, "")}>
              Cancel
            </Button>
            <Button sx={{width: "fit-content", justifySelf: "center"}} variant="contained" onClick={handleSubmit(onSubmit)}>
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}