import { 
  Box, 
  Button, 
  Card, 
  CardContent, 
  Checkbox, 
  Collapse, 
  FormControlLabel, 
  IconButton, 
  MenuItem, 
  Select, 
  Tab, 
  Table, 
  TableBody, 
  TableCell, 
  TableRow, 
  Tabs, 
  TextField, 
  Typography 
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect, useState } from "react";
import "./Order.css";
import { useGetMenuQuery, useGetRecipeImageUrlQuery } from "store/apis/MenusApi";
import { EditSkeleton } from "components/admin/admin.overlays";
import { useGetMealAddOnsQuery } from "store/apis/MealsApi";
import { useGetResidentActiveMealsByIdQuery, useGetResidentsAllQuery, useGetResidentTableSelectionQuery } from "store/apis/ResidentsApi";
import { useGetDiningAreasQuery } from "store/apis/DiningAreasApi";
import Media from "react-media";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import WarningIcon from "@mui/icons-material/Warning";
import HasSubRecipesIcon from "@mui/icons-material/Dehaze";
import diamond from "assets/imgs/diamond.png";
import Favorite from "@mui/icons-material/Favorite";
import { useDispatch, useSelector } from "react-redux";
import { selectGuestInfo, selectMenuInfo, setSelectedMeal } from "store/slices/orderSlice/OrderSlice";
import InfoModal from "components/shared/infoModal/InfoModal";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ImageModal from "components/shared/imageModal/ImageModal";
import _ from "lodash";
import SubRecipeModal from "components/shared/subRecipeModal/SubRecipeModal";
import MoneyInput from "@nodejsii/react-money-input";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import OrderConfirmModal from "components/shared/orderConfirmModal/OrderConfirmModal";
import { useAddHeldOrderMutation, useAddResidentOrderMutation, useDeleteFutureOrderMutation, useDeleteHeldOrderMutation } from "store/apis/OrdersApi";
import { DEFAULT_EMPTY, DIRECT_PRINT_DATE_OPTIONS, GUEST_TYPES, PAYMENT_TYPES } from "store/store.constants";
import ReasonModal from "components/shared/reasonModal/ReasonModal";

//Cannot use CSS className for print output, must use inline styles
const printStyles = {
  printGeneral: {
    display: 'flex',
    paddingBottom: '0px',
    paddingTop: '0px'
  },
  printDiv: {
    paddingBottom: '0px',
    paddingTop: '0px'
  },
}

export default function Order({ residentId, tableSideSetupId, isGuest, updateHeldOrders, isDirectPrint, allowMealCardNotes, currentUserData, handlePrint }) {
  const [ params ] = useSearchParams(); 
  const currentLocation = useLocation();
  const canHold = params.get('current') === 'true' || residentId === 0;// current or guest
  const isHeldOrder = params.get('from') === 'held';
  const isSubmitted = params.get('from') === 'submitted';
  const fromSP = isHeldOrder || isSubmitted;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedDiningAreaId, setSelectedDiningAreaId] = useState("");
  const [selectedTableName, setSelectedTableName] = useState("");
  const [outOfBuilding, setOutOfBuilding] = useState(false);
  const [declinedMeal, setDeclinedMeal] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(null);
  const [orderInfo, setOrderInfo] = useState({
    menuItems: [],
    alternateItems: [],
    order: {}
  });

  const [itemsChecked, setItemsChecked] = useState({
    alternateItems: {},
    menuItems: {},
  });
  const [collapsedRowStatus, setCollapsedRowStatus] = useState([
    {
      rowId: 0,
      colStatus: false,
    },
  ]);

  const [selectedSubRecipes, setSelectedSubRecipes]= useState([]);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const [imageRecipeId, setImageRecipeId] = useState(0);
  const [recipeImageUrl, setRecipeImageUrl] = useState("");
  const [openRecipeImage, setOpenRecipeImage] = useState(false);
  
  const [selectedAltTab, setSelectedAltTab] = useState(0);
  const [subRecipeModalOpen, setSubRecipeModalOpen] = useState(false);
  const [currentSubRecipe, setCurrentSubRecipe] = useState(null);
  const [currentSubRecipeNote, setCurrentSubRecipeNote] = useState("");
  const [showAdditionalCharges, setShowAdditionalCharges] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmType, setConfirmType] = useState(false);
  const [reasonModalOpen, setReasonModalOpen] = useState(false);
  const [reasonModalTitle, setReasonModalTitle] = useState(false);
  const [isSubmitAfterReason, setIsSubmitAfterReason] = useState(false);

  const [noteText, setNoteText] = useState("");
  const [addlCharges, setAddlCharges] = useState(0.00);

  const guestInfo = useSelector(selectGuestInfo)

  useEffect(() => {
    //if refreshed during guest order, not an edit from hold (fromSP), send user back to guest info page to start over
    if (!guestInfo?.guestName 
      && currentLocation.pathname.includes('/takeorder/guest/order')
      && !fromSP) {
        navigate(`/takeorder/guest`);
    }
  }, [guestInfo]);

  
  const storedMenuInfo = useSelector(selectMenuInfo);

  useEffect(() => {
    if (fromSP && storedMenuInfo) {
      let menuItemsChecked = Object.create({});
      let selectedSubRecipes = [];

      setDeclinedMeal(!!storedMenuInfo?.order?.declinedMeal);
      setOutOfBuilding(!!storedMenuInfo?.order?.outOfBuilding);

      storedMenuInfo.menuItems.forEach((item) => {
        menuItemsChecked[item.itemId] = true;

        if (item.subItemList?.length) {
          selectedSubRecipes.push(...item.subItemList.map((sub) => {
            return {
              ...sub,
              parentRecipeId: item.itemId,
              isAlternateItem: false
            }
          }));
          setCollapsedRowStatus((prev) => [...prev, {colStatus: true, rowId: item.itemId}]);
        }
      });

      let altItemsChecked = Object.create({});

      storedMenuInfo.alternateItems?.forEach((item) => {
        altItemsChecked[item.itemId] = true;

        if (item.subItemList?.length) {
          selectedSubRecipes.push(...item.subItemList.map((sub) => {
            return {
              ...sub,
              parentRecipeId: item.itemId,
              isAlternateItem: true
            }
          }));
          setCollapsedRowStatus((prev) => [...prev, {colStatus: true, rowId: item.itemId}]);
        }
      });

      setItemsChecked((prev) =>( {
        ...prev,
        alternateItems: altItemsChecked,
        menuItems: menuItemsChecked,
      }));

      setSelectedSubRecipes([...selectedSubRecipes]);

      setOrderInfo(() => ({
        guestPrice: storedMenuInfo.guestPrice ?? 0,
        menuItems: [...storedMenuInfo.menuItems.map((item) =>{ return {...item, subItemList: [...item.subItemList]} })],
        alternateItems: [...storedMenuInfo.alternateItems.map((item) =>{ return {...item, subItemList: [...item.subItemList]} })],
        order: {...storedMenuInfo.order}
      }));

      setNoteText(storedMenuInfo?.order?.note || '');

      if (storedMenuInfo?.order?.note?.length && storedMenuInfo?.order?.additionalCharges > 0){
        setShowAdditionalCharges(true);
      }
      setAddlCharges(storedMenuInfo?.order?.additionalCharges || 0.00);
    }
  }, [storedMenuInfo]);

  const {
    data: imgUrl,
    isFetching: fetchingUrl,
    isError: urlError,
    isSuccess: urlSuccess
  } = useGetRecipeImageUrlQuery(imageRecipeId,
    {refetchOnMountOrArgChange: true, skip: !imageRecipeId}
  );
  
  useEffect(() => {
    if (!fetchingUrl && urlSuccess) {
      setRecipeImageUrl(imgUrl);
    }
  }, [fetchingUrl, urlSuccess]);

  useEffect(() => {
    if (urlError) {
      setRecipeImageUrl("");
    }
    
    if(imageRecipeId) {
      setOpenRecipeImage(true);
    }
  }, [recipeImageUrl, urlError]); //still open with falsy value, template will show error
  
  let residentIdForQueries;

  if (isGuest) {
    residentIdForQueries = guestInfo?.selectedResident;
  } else {
    if (guestInfo?.selectedResident) {
      residentIdForQueries = null;
    } else {
      residentIdForQueries = residentId;
    }
  }

  const {
    data: menuInfo,
    isFetching: fetchingMenu
  } = useGetMenuQuery(
    {
      residentId: residentId,
      setupId: tableSideSetupId,
      isGuest: !!isGuest,
      includeDiningAreas: true,
      guestResidentId: isGuest && guestInfo?.selectedResident ? guestInfo?.selectedResident : 0,
      guestName: isGuest ? guestInfo?.guestName?.trim() : "" ,
      guestBillingTypeId: isGuest ? guestInfo?.paymentDone : 0
    }, 
    { skip: ((!residentId || !tableSideSetupId) && !isGuest) }
  );
  
  useEffect(() => {
    if (menuInfo && !fromSP) {
      const newOrderInfo = Object.assign({}, menuInfo);
      newOrderInfo['menuItems'] = [];
      newOrderInfo['alternateItems'] = [];
      setOrderInfo(newOrderInfo);
    } else if (menuInfo && fromSP) {
      //just add menuChoices we need from API call when using stored data 
      setOrderInfo((prev) => ({
        ...prev,
        menuChoices: [...menuInfo.menuChoices],
        alternateChoices: [...menuInfo.alternateChoices],
        orderId: menuInfo?.orderId,
        roomBed: menuInfo?.roomBed,
        residentName: menuInfo?.residentName,
      }));
    }
  }, [menuInfo]);

  const {
    data: mealAddons,
    isFetching: fetchingAddons
  } = useGetMealAddOnsQuery(  
    { 
      residentId: residentId, 
      setupId: tableSideSetupId, 
      isGuest: !!isGuest
    },
    { skip: ((!residentId || !tableSideSetupId) || isGuest) }
  );

  const {
    data: residentTables,
    isFetching: fetchingTable
  } = useGetResidentTableSelectionQuery(residentIdForQueries,
    { skip: !residentId && !guestInfo.selectedResident }
  )
  
  const {
    data: diningAreas,
    isFetching: fetchingAreas
  } = useGetDiningAreasQuery();

  const {
    data: residents,
    isFetching: fetchingResidents,
    // refetch: refetchResidentList
  } = useGetResidentsAllQuery();

  const {
    data: activeMeals,
    isFetching: fetchingMeals,
  } = useGetResidentActiveMealsByIdQuery(residentId,
    { refetchOnMountOrArgChange: true, skip: !residentId }
  );
  
  const isLoading = fetchingMenu 
                  || fetchingAddons 
                  || fetchingTable 
                  || fetchingAreas 
                  || fetchingResidents
                  || fetchingMeals;
  
  useEffect(() => {
    if (menuInfo && diningAreas) {
      dispatch(setSelectedMeal(menuInfo?.mealName)); // for display in navbar

      const mealName = menuInfo?.mealName?.toLowerCase()?.trim();
      let diningAreaId = "";
      let diningTableName = "";
      
      // find current meal and table/dining area to setState.
      if (!fromSP) {
        if (mealName?.startsWith('breakfast')) {
          diningAreaId = residentTables?.[0]?.diningAreaId > 0 ? residentTables?.[0]?.diningAreaId : "";
          diningTableName = residentTables?.[0]?.tableName || "";
        } else if (mealName?.startsWith('lunch')) {
          diningAreaId = residentTables?.[1]?.diningAreaId > 0 ? residentTables?.[1]?.diningAreaId : "";
          diningTableName = residentTables?.[1]?.tableName || "";
        } else if (mealName?.startsWith('dinner')) {
          diningAreaId = residentTables?.[2]?.diningAreaId > 0 ? residentTables?.[2]?.diningAreaId : "";
          diningTableName = residentTables?.[2]?.tableName || "";
        }
      } else {
        diningAreaId = storedMenuInfo?.order?.diningAreaId;
        diningTableName = storedMenuInfo?.order?.tableName;
      }
      
      selectDiningArea(diningAreaId);
      selectTable(diningTableName);
      
      if (menuInfo.fluidRestriction) {
        setModalTitle('Fluid Restriction')
        setModalText(`This resident has a fluid restriction!`);
        setInfoModalOpen(true);
      }
    }
  }, [residentTables, menuInfo, diningAreas]);

  useEffect(() => {
    let activeMeal;
    
    activeMeals?.days?.forEach((day) => {
      day?.meals?.forEach((meal) => {
        if (meal?.setupId == tableSideSetupId) {
          activeMeal = meal;
        }
      })
    });

    if (activeMeal?.orderTaken && !fromSP) {
      setModalTitle("ALERT");
      setModalText("You have already taken an order for this person.\nThis order will be sent in addition to the original order.\nIf you want to reprint the original order, go to the 'Submitted' orders tab.")
      setInfoModalOpen(true);
    }
  }, [activeMeals]);
  
  const handleModalClose = () => {
    setInfoModalOpen(false);
  }

  const checkForDietNameSeparator = () => {
    return (menuInfo.dietName !== "N/A" 
      && menuInfo.dietName 
      && !DEFAULT_EMPTY.includes(menuInfo.dietTextureName) 
      && menuInfo.dietTextureName !== "Regular" 
      && menuInfo.dietTextureName);
  }

  const checkForDietOtherSeparator = () => {
    return (menuInfo.dietName !== "N/A" && menuInfo.dietName && menuInfo.dietTextureName !== "N/A" &&
      menuInfo.dietTextureName && menuInfo.dietOther !== "N/A" && menuInfo.dietOther) ||
      (menuInfo.dietName && !menuInfo.dietTextureName && menuInfo.dietOther) ||
      (!menuInfo.dietName && menuInfo.dietTextureName && menuInfo.dietOther)
  }

  const selectDiningArea = (diningAreaId) => {
    const newTables = diningAreas.find((area) => area.id === diningAreaId)?.tables;
    setSelectedDiningAreaId(() => diningAreaId);
    renderTables(); //update Select component render list to prevent warning in next if-condition

    if (!newTables?.some((table) => table.name === selectedTableName)) {
      setSelectedTableName(newTables?.[0]?.name ?? 'N/A');
    }

  }

  const renderDiningAreas = () => {
    const areas = [];

    if (diningAreas?.length) {
      diningAreas.forEach((area) => {
        areas.push(
          <MenuItem key={area.id} value={area.id}>
            <span className="general-text"style={{fontWeight: "bold"}}>{area.name}</span>
          </MenuItem>
        );
      });
    }

    return areas;
  }

  const selectTable = (tableName) => {
    setSelectedTableName(tableName);
    setOrderInfo((prev) => ({
      ...prev,
      order: {
        ...prev.order,
        tableName: tableName
      }
    }));
  }

  const renderTables = () => {
    const tables = [];

    if (selectedDiningAreaId) {
      const diningArea = diningAreas.find((area) => area.id === selectedDiningAreaId);

      if (diningArea && diningArea?.tables?.length) {
        diningArea.tables.forEach((table) => {
          tables.push(
            <MenuItem key={table.id} value={table.name}>
              <span className="general-text" style={{fontWeight: "bold"}}>{table.name}</span>
            </MenuItem>
          );
        });
      } else {
        tables.push(
          <MenuItem key={"N/A"} value={"N/A"}>
          <span className="general-text" style={{fontWeight: "bold"}}>{"N/A"}</span>
          </MenuItem>
        );
      }
    }

    return tables;
  }

  const handleOutOfBuildingChange = () => {
    setOutOfBuilding((prev) => !prev);
    setDeclinedMeal(false);
  }

  const handleDeclinedMealChange = () => {
    setDeclinedMeal((prev) => !prev);
    setOutOfBuilding(false);
  }

  useEffect(() => {
    if (outOfBuilding || declinedMeal) {
      // only removes main menu items, handle alt items, subRecipes, and items checked previously manually
      setIsSelectAll(false); 
      setOrderInfo((prev) => ({
        ...prev,
        alternateItems: []
      }));
      setSelectedSubRecipes([]);
      setItemsChecked({
        alternateItems: {},
        menuItems: {}
      });
    }
  }, [outOfBuilding, declinedMeal]);

  useEffect(() => {
    if (isSelectAll !== null) {
      menuInfo?.menuChoices?.forEach((item) => {
        if (!item?.isAllergy) {
          handleMenuItemClick(item, 'menuItems', isSelectAll);
        }
      });
  
      // concurrency memory reference issue needs workaround for deselect all
      // it removes from the checked item lists properly, so visually it looks fine
      // but ends up leaving the actual items in the array. This bug exists in legacy 
      // and a reset of menuItems to an empty array in the state object is the simplest solution
      if (!isSelectAll) {
        setOrderInfo((prev) => ({
          ...prev,
          menuItems: []
        }))
      }
    }
  }, [isSelectAll]);

  const removeSelectedSubRecipeFromList = (parentRecipeId, isAltRecipe) => {
    setSelectedSubRecipes((prev) => [...prev.filter((sub) => sub.parentRecipeId !== parentRecipeId || sub.isAlternateItem !== isAltRecipe)]);
    setCollapsedRowStatus((prev) => [...prev.filter((row) => row.rowid !== parentRecipeId || row.isAlternateItem !== isAltRecipe)]);
  }

  const handleMenuItemClick = (item, choice, isChecked) => {
    const tempOrderInfo = Object.assign({}, orderInfo);
    const tempItemsChecked = itemsChecked;
    let guestPrice = orderInfo?.['guestPrice'] ?? 0;

    if (isChecked) {

      tempOrderInfo[choice].push({
        itemId: item.recipeId,
        quantity: 1,
        guestPrice: item.guestPrice,
        portionSize: item.portionSize,
        name: item.recipeName,
        menuCategory: item.menuCategory,
        isSubstitution: item.isSubstitution
      });

      tempItemsChecked[choice][item.recipeId] = true;
      guestPrice += item.guestPrice;

      if (menuInfo.fluidRestriction && item.applicableToFluidRestriction) {
        setModalTitle("Fluid Restriction")
        setModalText("This resident has a fluid restriction.\n" + 
          "Make sure that this selection doesn't exceed the resident's restriction."
        );
        setInfoModalOpen(true);
      }
    } else {
      tempOrderInfo[choice] = tempOrderInfo[choice]?.filter((orderItem) => {
        if (orderItem.itemId === item.recipeId) {
          guestPrice = guestPrice - (orderItem.guestPrice * orderItem.quantity);
          orderItem.quantity = '';
        }
        return orderItem.itemId !== item.recipeId;
      });

      tempItemsChecked[choice][item.recipeId] = false;
      removeSelectedSubRecipeFromList(item.recipeId, item.isAlternateRecipe);
    }

    setItemsChecked(tempItemsChecked);
    setOrderInfo((prev) => ({
      ...prev,
      alternateItems: [...tempOrderInfo.alternateItems],
      menuItems: [...tempOrderInfo.menuItems],
      guestPrice: guestPrice
    }));
  }

  const selectQuantity = (item, choice, value) => {
    const tempOrderInfo = Object.assign({}, orderInfo);

    tempOrderInfo[choice]?.forEach((orderItem) => {
      if (orderItem.itemId === item.recipeId) {
        setOrderInfo((prev) => ({
          ...prev,
          guestPrice: prev.guestPrice + ((value - orderItem.quantity) * item.guestPrice)
        }));

        orderItem.quantity = value;
      }
    });
  }

  const renderQuantity = (maxQuantity) => {
    const sizes = [];
    const servingSizes = [1/2];

    for (let i = 1; i <= maxQuantity; i++) {
      servingSizes.push(i);
    }

    servingSizes.forEach((size) => {
      sizes.push(
        <MenuItem key={size} value={size}>
          <span className="general-text">{size}</span>
        </MenuItem>
      );
    });

    return sizes;
  }

  const renderSubMenuModal = (event, subRecipe) => {
    event.stopPropagation();
    const menuItem = orderInfo.menuItems.find((item) => item.itemId === subRecipe.recipeId);
    const altItem = orderInfo.alternateItems.find((item) => item.itemId === subRecipe.recipeId);
    setCurrentSubRecipeNote(menuItem?.specialNote || altItem?.specialNote || "");
    setCurrentSubRecipe(subRecipe);
    setSubRecipeModalOpen(true);
  }

  const handleSubRecipeClose = (recipeId, selectedSubRecipes, specialNote = "") => {
    const isAlternate = currentSubRecipe?.isAlternateRecipe;

    if (!isAlternate) {
      const rowId = currentSubRecipe.recipeId;
      let tempCollapseStatus = collapsedRowStatus;
      const totalSubsForItem = selectedSubRecipes?.length || 0;

      if (totalSubsForItem > 0) {
        const temp = tempCollapseStatus.filter((row) => row.rowId === rowId);

        if (temp?.length > 0) {
          temp.at(0).colStatus = true;
        } else {
          tempCollapseStatus.push({ rowId: rowId, colStatus: true });
        }
      } else {
        tempCollapseStatus = tempCollapseStatus.filter((row) => row.rowId !== rowId);
      }

      setCollapsedRowStatus(tempCollapseStatus);
    }

    const menuItem = orderInfo.menuItems.find((item) => item.itemId === recipeId);
    const altItem = orderInfo.alternateItems.find((item) => item.itemId === recipeId);

    // add field to reference later
    if (menuItem) {
      menuItem["specialNote"] = specialNote;
    } else if (altItem) {
      altItem["specialNote"] = specialNote;
    }

    setSelectedSubRecipes((prev) => [...prev.filter((old) => old.parentRecipeId !== recipeId), ...selectedSubRecipes]);
    setSubRecipeModalOpen(false);
  }

  const handlePopUpImage = (recipeId) => {
    // if we already have the imageUrl for that recipe, just open the modal
    if (imageRecipeId === recipeId) {
      setOpenRecipeImage(true);
    } else {
      setImageRecipeId(recipeId);
    }
  }

  const renderMenuItems = () => {
    const menuItems = [];

    if (menuInfo) {
      menuInfo?.menuChoices?.forEach((item) => {
        let selectedValue = "";
        let specialNote = "";
        orderInfo?.menuItems?.forEach((orderItem) => {
          if (orderItem?.itemId === item.recipeId) {
            selectedValue = orderItem?.quantity;
                
            if (orderItem?.specialNote) {
              specialNote = orderItem.specialNote;
            }
          }
        });

        if (selectedValue === undefined) selectedValue = "";

        let flag = itemsChecked?.menuItems?.[item.recipeId];

        let rowStatus = collapsedRowStatus;
        let status = rowStatus?.filter((row) => row?.rowId === item.recipeId);
        let tempStatus = status.length ? status[0]?.colStatus : false;

        menuItems.push(
          <TableRow 
            key={item.recipeId} 
            sx={{
              borderBottom: "1px solid rgba(128, 128, 128, .5)",
              backgroundColor: flag ? '#cee2e3' : "#fff",
              width: "100%",
              borderRadius: "1rem",
              display: "flex",
              justifyContent: "flex-start",
              margin: ".25rem 0"
            }}
          >
            <TableCell className="noBorderCell" style={{width: "10px", justifyContent: "center", padding: "0"}}>
              <Grid container justifyContent="center" alignItems="center">
                <IconButton
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (status?.length) {
                      status[0].colStatus = !tempStatus;
                      setCollapsedRowStatus([...rowStatus]);
                    }
                  }}    
                >
                  {tempStatus ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Grid>
            </TableCell>
            <TableCell className="noBorderCell" style={{padding: "0", cursor: "pointer", width: "-webkit-fill-available"}} 
            onClick={() => {
              if (!item.isAllergy) {
                handleMenuItemClick(item, "menuItems", !flag)
              }
            }}
            >
              <Grid container justifyContent="flex-start" alignItems="flex-start">
                <Media query="(max-width: 599px)">
                  {(matches) => 
                    matches ? (
                      <span style={{fontSize: "1.75rem", fontWeight: "fontWeightMedium"}}>{item.recipeName}{specialNote ? ' - ' + specialNote : null}</span>
                    ) : (
                      <span style={{fontSize: "1.75rem", fontWeight: "fontWeightMedium"}}>{item.recipeName}{specialNote ? ' - ' + specialNote : null}</span>
                    )
                  }
                </Media>
              </Grid>
            </TableCell>
            <TableCell className="noBorderCell" sx={{marginLeft: "auto"}}>
              {item.isAllergy && (
                  <Grid container justifyContent="center" alignItems="center" flexWrap="nowrap">
                    <WarningIcon className="warning" />
                      <Typography className="warning">
                        <span className="warning">{" ALLERGY"}</span>
                      </Typography>
                  </Grid>
              )}
            </TableCell>
            <TableCell className="noBorderCell" sx={{padding: "0"}}>
              <Grid container justifyContent="flex-end" alignItems="center" flexWrap="nowrap">
                {flag && item?.hasSubRecipes && (
                  <HasSubRecipesIcon style={{color: "black", marginRight: "5px", backgroundColor: "#f2c745", borderRadius: ".25rem", cursor: "pointer"}} 
                    onClick={(event) => renderSubMenuModal(event, item)} />
                )}

                {item.imageAvailable && <CameraAltIcon style={{color: "#2F4F4F", marginRight: "5px"}} onClick={() => handlePopUpImage(item.recipeId)} /> }
                {item.imageAvailable && <ImageModal open={openRecipeImage} sourceUrl={recipeImageUrl} handleClose={() => setOpenRecipeImage(false)} />}
                {item.isHeartHealthy && <Favorite style={{color: "#ab403d", marginRight: "5px"}} />}
                {item.isDiabeticChoice && (
                  <img alt="diamond-icon" src={diamond} style={{width: "27px", height: "28px", marginRight: "5px"}} />
                )} 

                <Select
                  variant="standard"
                  style={{marginRIght: "5px"}}
                  disabled={!flag}
                  value={selectedValue}
                  onChange={(event) => selectQuantity(item, "menuItems", event.target.value)}
                  onClick={(e) => e.stopPropagation()}
                >
                  {renderQuantity(item.maxNumberOfServings)}
                </Select>
              </Grid>
            </TableCell>
          </TableRow>
        );
        const subRecipes = selectedSubRecipes?.filter((sub) => sub.parentRecipeId === item.recipeId && !sub.isAlternateItem);

        subRecipes.forEach((subRecipe) => {
          menuItems.push(
            // Weird nesting but needed to work around DOM nesting errors (td child of div) with Collapse element being inserted into table structure
            <TableRow key={subRecipe.recipeId} 
              sx={{
                height: tempStatus ? "auto" : "0px !important", 
                borderBottom: tempStatus ? "1px solid rgba(128, 128, 128, .5)" : "none"
              }}
            >
              <TableCell className="noBorderCell" sx={{padding: "0 !important"}}>
                <div style={{
                  backgroundColor: "#cee2e3",              
                  width: "100%",
                  borderRadius: "1rem",
                  justifyContent: "flex-start",
                  margin: tempStatus ? ".25rem 0" : "0"
                  }}
                >
                  <Collapse in={tempStatus} timeout="auto" unmountOnExit>
                    <Table sx={{marginLeft: "3rem"}}>
                      <TableBody>
                        <TableRow>
                          <TableCell className="noBorderCell">
                            <Typography sx={{fontSize: "1.75rem", fontStyle: "italic", lineHeight: "1"}}>
                              {subRecipe.recipeName} - {subRecipe.quantity}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Collapse>
                </div>
              </TableCell>
            </TableRow>
          )
        });
      });
    }

    return menuItems;
  }

  const handleTabChange = (event, newValue) => {
    setSelectedAltTab(newValue);
  }

  const renderAlternateItemsTabs = () => {
    const alternateTabs = [];
    let tabstrip = <></>;


    if (menuInfo !== null) {
      const groupedItems = _.groupBy(menuInfo?.alternateChoices, _.property("menuCategory"));
      let idx = 0;
      for (const key in groupedItems) {
        if (groupedItems[key]?.length) {
          alternateTabs.push(
            <Tab key={key} label={key} value={idx}/>
          );
          idx++;
        }
      }

      tabstrip = (
        <Box sx={{width: "100%"}}>
          <Box sx={{marginBottom: "1rem"}}>
            <Tabs 
              id="altTabs"
              value={selectedAltTab} 
              onChange={handleTabChange}  
              variant="scrollable"
              scrollButtons={true}   
              allowScrollButtonsMobile    
              TabIndicatorProps={{
              sx: {
                display: "none"
              }
            }}>
              {alternateTabs.map((tab) => (tab))}
            </Tabs>
          </Box>
          {Object.keys(groupedItems).map((key, idx) => {
            return (
              <div key={idx} hidden={selectedAltTab !== idx}  role="tabpanel"> 
                {selectedAltTab === idx && (
                  <Table>
                    <TableBody>{renderListItems(groupedItems[key])}</TableBody>
                  </Table>
                )}
              </div>
            )
          })}
        </Box>
      );
    }


    return tabstrip;
  }

  const renderListItems = (items) => {
    const list = [];

    _.uniqBy(items, _.property("recipeId"))?.forEach((item) => {
      let flag = itemsChecked?.alternateItems?.[item.recipeId];
      let selectedValue = "";
      let specialNote = ""

      orderInfo?.alternateItems?.forEach((alt) => {
        if (alt.itemId === item.recipeId) {
          selectedValue = alt.quantity ?? undefined;
          
          if (alt?.specialNote) {
            specialNote = alt.specialNote;
          }
        }
      });

      if (selectedValue === undefined) { selectedValue = ""; }

      list.push(
        <TableRow 
          key={item.recipeId} 
          sx={{
            borderBottom: "1px solid rgba(128, 128, 128, .5)",
            backgroundColor: flag ? '#cee2e3' : "#FFF",
            width: "100%",
            borderRadius: "1rem",
            display: "flex",
            justifyContent: "flex-start",
            padding: ".25rem 0",
            margin: ".25rem 0"
          }}
        >
          <TableCell 
            className="noBorderCell"
            sx={{cursor: "pointer", width: "-webkit-fill-available"}}           
            onClick={() => {
              if (!item.isAllergy) {
                handleMenuItemClick(item, "alternateItems", !flag)
              }
          }}>
            <Grid container justifyContent="flex-start" alignItems="flex-start">
              <Media query="(max-width: 599px)">
                {(matches) => 
                  matches ? (
                    <span style={{fontSize: "1.75rem", fontWeight: "fontWeightMedium"}}>{item.recipeName} {specialNote ? ' - ' + specialNote : null}</span>
                  ) : (
                    <span style={{fontSize: "1.75rem", fontWeight: "fontWeightMedium"}}>{item.recipeName} {specialNote ? ' - ' + specialNote : null}</span>
                  )
                }
              </Media>
            </Grid>
          </TableCell>
          <TableCell sx={{marginLeft: "auto"}} className="noBorderCell">
            {item.isAllergy && (
              <Grid container justifyContent="center" alignItems="center" flexWrap="nowrap">
                <WarningIcon className="warning"/>
                <Typography className="warning">
                  <span className="warning">{" ALLERGY"}</span>
                </Typography>
              </Grid>
            )}
          </TableCell>
          <TableCell className="noBorderCell" sx={{padding: "0"}}>
              <Grid container justifyContent="flex-end" alignItems="center" flexWrap="nowrap">
                {flag && item?.hasSubRecipes && (
                  <HasSubRecipesIcon style={{color: "black", marginRight: "5px", backgroundColor: "#f2c745", borderRadius: ".25rem"}} 
                    onClick={(event) => renderSubMenuModal(event, item)} />
                )}

                {item.imageAvailable && <CameraAltIcon style={{color: "#2F4F4F", marginRight: "5px"}} onClick={() => handlePopUpImage(item.recipeId)} /> }
                {item.imageAvailable && <ImageModal open={openRecipeImage} sourceUrl={recipeImageUrl} handleClose={() => setOpenRecipeImage(false)} />}
                {item.isHeartHealthy && <Favorite style={{color: "#ab403d", marginRight: "5px"}} />}
                {item.isDiabeticChoice && (
                  <img alt="diamond-icon" src={diamond} style={{width: "27px", height: "28px", marginRight: "5px"}} />
                )} 

                <Select
                  variant="standard"
                  style={{marginRIght: "5px"}}
                  disabled={!flag}
                  value={selectedValue}
                  onChange={(event) => selectQuantity(item, "alternateItems", event.target.value)}
                  onClick={(e) => e.stopPropagation()}
                >
                  {renderQuantity(item.maxNumberOfServings)}
                </Select>
              </Grid>
          </TableCell>
        </TableRow>
      );

      const subRecipes = selectedSubRecipes.filter((sub) => sub.parentRecipeId === item.recipeId && sub.isAlternateItem);

      _.uniqBy(subRecipes, _.property('recipeId')).forEach((subRecipe) => {
        list.push(
          <TableRow 
            key={subRecipe.recipeId} 
            sx={{
              borderBottom: "1px solid rgba(128, 128, 128, .5)",               
              backgroundColor: "#cee2e3", 
              width: "100%",
              borderRadius: "1rem", 
              height: "2.5rem", 
              display: "flex", 
              alignContent: "center",
              margin: ".25rem 0"
            }}
          >
            <TableCell className="noBorderCell">
              <span style={{lineHeight: "1", paddingLeft: "4rem", fontSize: "1.75rem", fontStyle: "italic"}}>{subRecipe.recipeName} - {subRecipe.quantity}</span>
            </TableCell>
          </TableRow>
        );
      })
    });

    
    return list;
  }

  const handleSubmit = (confirmType) => {
    // enforce selection when not declining or out of building
    if (!selectedDiningAreaId && !outOfBuilding && !declinedMeal) {
      setModalTitle(""); // reset just in case other with title has been used 
      setModalText("Dining Area cannot be blank.")
      setInfoModalOpen(true);
      return;
    }

    if (addlCharges > 100) {
      return;
    }

    const tempMenuItems = [...orderInfo.menuItems];
    const tempAltItems = [...orderInfo.alternateItems];

    tempMenuItems.forEach((item) => {
      const subRecipes = selectedSubRecipes.filter((sub) => sub.parentRecipeId === item.itemId && !sub.isAlternateItem);
      item['subItemList'] = [...subRecipes];
    });

    tempAltItems.forEach((item) => {
      const subRecipes = selectedSubRecipes.filter((sub) => sub.parentRecipeId === item.itemId && sub.isAlternateItem);
      item['subItemList'] = [...subRecipes];
    })

    //update fields we removed from onChange for performance reasons (large object re-writes)
    //and put menu/alt items back with selected subrecipes bound to parent
    setOrderInfo((prev) => ({
      ...prev,
      menuItems: [...tempMenuItems],
      alternateItems: [...tempAltItems],
      order: {
        ...prev.order,
        additionalCharges: addlCharges,
        declinedMeal: declinedMeal,
        diningAreaId: selectedDiningAreaId || 0, //fallback for declined/OOB (call fails as null), otherwise selection is enforced anyway
        guest: isGuest ? guestInfo?.guestName?.trim() : null,
        guestBillingTypeId: isGuest ? guestInfo?.paymentDone : 0,
        guestTypeId: isGuest ? guestInfo?.selectedGuestType : 0,
        note: noteText,
        outOfBuilding: outOfBuilding,
        tableName: selectedTableName || "",
      }
    }));

    // run this after order has been updated to short circuit confirm modal
    if (outOfBuilding || declinedMeal) {
      setReasonModalTitle(outOfBuilding ? "Out of Building" : "Declined Meal");
      setReasonModalOpen(true);
      return;
    }
  
    setConfirmType(confirmType)
    setConfirmModalOpen(true);
  }

  const [
    addHoldOrder,
    {
      isSuccess: holdSuccess,
      isError: holdError
    }
  ] = useAddHeldOrderMutation();

  useEffect(() => {
    if (holdSuccess && !holdError) {
      updateHeldOrders();
      if (isGuest) {
        navigate(`/takeorder/guest`);
      } else {
        navigate('/takeorder/residents');
      }
    }
  }, [holdSuccess, holdError])

  const [
    addNewOrder,
    {
      isSuccess: orderSuccess,
      isError: orderError
    }
  ] = useAddResidentOrderMutation();

  useEffect(() => {
    if (orderSuccess && !orderError) {
      if (fromSP) {
        // attempt to clean up on submit when stored data present
        if (isHeldOrder) {
          deleteHeldOrder(storedMenuInfo?.order?.id); 
        } else if (isSubmitted) {
          deleteOrder(storedMenuInfo?.order?.id);
        }
      } else {
        if (isGuest) {
          navigate(`/takeorder/guest`);
        } else {
          navigate(`/takeorder/residents`);
        }
      }
    }
  }, [orderSuccess, orderError]);

  const [deleteHeldOrder, { isSuccess: deleteHeldSuccess }] = useDeleteHeldOrderMutation();
  const [deleteOrder, { isSuccess: deleteOrderSuccess }] = useDeleteFutureOrderMutation();

  useEffect(() => {
    if (deleteHeldSuccess || deleteOrderSuccess) {
      navigate(`/takeorder/residents`);
    }
  }, [deleteHeldSuccess, deleteOrderSuccess])

  const handleConfirmClose = (canContinue) => {
    setConfirmModalOpen(false);

    if (canContinue) {
      if (confirmType === 'HOLD') {
        addHoldOrder(orderInfo); 
      } else if (confirmType === 'SUBMIT') {
        if (isDirectPrint) {
          const html = document.getElementById('printSection').cloneNode(true);
          handlePrint(html);
        }

        addNewOrder(orderInfo);
      }   
    }
  }

  const handleReasonClose = (canContinue, reasonText = "") => {
    setReasonModalOpen(false);
    setReasonModalTitle("");

    if (canContinue) {
      setOrderInfo((prev) => ({
        ...prev,
        order: {
          ...prev.order,
          note: reasonText,
        }
      }));
      setIsSubmitAfterReason(true);
    }
  }

  useEffect(() => {
    if (isSubmitAfterReason) {
      addNewOrder(orderInfo);
      setIsSubmitAfterReason(false);
    }
  }, [isSubmitAfterReason]); //due to async order update, have to use a hook 

  const getResidentNameById = (residentId) => {
    return residents?.find((resident) => resident.id === residentId)?.name?.split("(")?.[0]?.trim() || "";
  }

  const getResidentWingById = (residentId) => {
    return residents?.find((resident) => resident.id == residentId)?.wing || "";
  }

  const getDiningAreaNameById = (diningAreaId) => {
    return diningAreas?.find((area) => area.id === diningAreaId)?.name?.trim() || "";
  }

  const renderPrintItems = () => {
    const menuList = [];

    orderInfo?.menuItems?.forEach((item) => {
      const quantity = item.quantity.toFixed(2);
      const subRecipes = selectedSubRecipes.filter((sub) => sub.parentRecipeId == item.itemId);

      menuList.push(
        <div key={item.itemId} style={printStyles.printDiv}>
          <span>{quantity} - {item.portionSize || ''} <b>{item.name}</b></span>
        </div>
      );

      if (item?.specialNote) {
        menuList.push(
          <div key={`${item.itemId}-specialNote`} style={{paddingY: "0px", paddingLeft: "24px"}}>
            <span><b>{`Special Note: ${item.specialNote}`}</b></span>
          </div>
        );
      }

      subRecipes.forEach((sub) => {
        menuList.push(
          <div key={sub.recipeId} style={{paddingY: "0px", paddingLeft: "24px"}}>
            <span><b>{`Add - ${sub.recipeName} (${sub.quantity})`}</b></span>
          </div>
        )
      });
    });

    orderInfo?.alternateItems?.forEach((alt) => {
      const quantity = alt.quantity.toFixed(2);
      const subRecipes = selectedSubRecipes.filter((sub) => sub.parentRecipeId == alt.itemId);
      
      menuList.push(
        <div key={alt.itemId} style={printStyles.printDiv}>
          <span>{quantity} - {alt.portionSize || ''} <b>{alt.name}</b></span>
        </div>
      );

      if (alt?.specialNote) {
        menuList.push(
          <div key={`${alt.itemId}-specialNote`} style={{paddingY: "0px", paddingLeft: "24px"}}>
            <span><b>{`Special Note: ${alt.specialNote}`}</b></span>
          </div>
        );
      }

      subRecipes.forEach((sub) => {
        menuList.push(
          <div key={sub.recipeId} style={{paddingY: "0px", paddingLeft: "24px"}}>
            <span><b>{`Add - ${sub.recipeName} (${sub.quantity})`}</b></span>
          </div>
        )
      });
    });

    return menuList;
  }

  const renderPrintContent = () => {
    const diningAreaName = getDiningAreaNameById(orderInfo?.order?.diningAreaId);
    const residentWing = getResidentWingById(residentId);
    const isIsolation = residents.find((res) => res.id == residentId)?.isIsolation;

    return (
      <div style={{padding: "10px 5px 5px 5px", fontSize: "15px", maxWidth: "300px"}}>
          <div style={{fontSize: "1.25rem", fontWeight: "bold", paddingY: "5px"}}>
            {isGuest ? orderInfo?.order?.guest : orderInfo?.residentName}
          </div> 
          {isIsolation && <div style={{fontWeight: "bold", paddingY: "5px"}}>
            * ISOLATION *
          </div>
          }   
          {!!orderInfo.order.guestOfResidentId && (
            <div style={printStyles.printDiv}>Guest/Employee: {getResidentNameById(orderInfo.order.guestOfResidentId)}</div>
          )}
          {isGuest && (
            <div style={printStyles.printDiv}>
              Pamyent Status: {PAYMENT_TYPES[orderInfo.order.guestBillingTypeId]}  
            </div>
          )}
          {diningAreaName !== "" && 
            <div style={printStyles.printGeneral}>
              <span style={{marginRight: ".75rem", fontWeight: "bold", fontSize: ".875rem"}}>
                {diningAreaName.replace(/Dining Room/gi, 'DR')}
              </span>
              <span style={{fontWeight: "bold", fontSize: ".875rem"}}>
                {!DEFAULT_EMPTY.includes(orderInfo?.order?.tableName) ? `Table: ${orderInfo.order.tableName}` : null}
              </span>
            </div>
          }
          {!DEFAULT_EMPTY.includes(orderInfo?.roomBed) &&
            <div style={printStyles.printGeneral}>
              <span style={{fontSize: ".75rem", marginBottom: ".5rem"}}>
                {`Wing: ${residentWing || 'N/A'}`}
              </span>
              <span style={{margin: "0 .5rem", fontSize: ".75rem"}}>|</span>
              <span style={{fontSize: ".75rem"}}>
                {`Room: ${orderInfo.roomBed}`}
              </span>
            </div>
          }
            
          <div style={{display: "flex", fontSize: ".675rem"}}>
            <div id="label-col" style={{minWidth: "75px"}}>
              {!DEFAULT_EMPTY.includes(orderInfo.dietName) && <div>Diet:</div> }
              {!DEFAULT_EMPTY.includes(orderInfo.dietTextureName) && <div>Texture:</div> }
              {!DEFAULT_EMPTY.includes(orderInfo.dietOther) && <div>Other:</div> }
              {!DEFAULT_EMPTY.includes(orderInfo.liquidConsistency) && <div>Liquid:</div> }
              {!DEFAULT_EMPTY.includes(orderInfo.allergies) && <div><i>Allergies:</i></div> }
            </div>
            <div id="value-col" style={{whiteSpace: "pre"}}>
              {!DEFAULT_EMPTY.includes(orderInfo.dietName) && <div><b>{orderInfo.dietName}</b></div>}
              {!DEFAULT_EMPTY.includes(orderInfo.dietTextureName) && <div><b>{orderInfo.dietTextureName}</b></div>}
              {!DEFAULT_EMPTY.includes(orderInfo.dietOther) && <div><b>{orderInfo.dietOther}</b></div>}
              {!DEFAULT_EMPTY.includes(orderInfo.liquidConsistency) && <div><b>{orderInfo.liquidConsistency}</b></div>}
              {!DEFAULT_EMPTY.includes(orderInfo.allergies) && <div><i>{orderInfo.allergies}</i></div>}
            </div>
          </div>

          <div style={{marginTop: "1rem", fontSize: ".75rem", marginBottom: "1rem"}}>
            { renderPrintItems() }
            <div style={printStyles.printGeneral}>
                <i>Added Notes: {noteText || ""}</i>
              </div>
          </div>

          <div style={{marginBottom: "1.5rem", fontSize: ".75rem"}}>
            {allowMealCardNotes && !!orderInfo.notes?.length &&
              <div style={printStyles.printGeneral}>
                MealCard Notes: {orderInfo?.notes.join(', ')}
              </div>
            }
            {!DEFAULT_EMPTY.includes(orderInfo?.adaptiveEquipment) &&
              <div style={printStyles.printGeneral}>
                Adaptive Equip: {orderInfo?.adaptiveEquipment}
              </div>
            }
            {!DEFAULT_EMPTY.includes(orderInfo?.fluidRestriction) &&
              <div style={printStyles.printGeneral}>
                Fluid Restrict: {orderInfo?.fluidRestriction}
              </div>
            }
          </div>

          <div style={{fontSize: ".75rem"}}>
            <div>
              <span><b>Total Price:</b> ${orderInfo?.guestPrice?.toFixed(2)}</span>
            </div>
            <div>
              <span><b>Signature: __________________________________</b></span>
            </div>
          </div>
          <div style={{fontSize: "1.25rem", fontWeight: "bold", textAlign: "center"}}>
            { orderInfo.order.residentId === 0 ? orderInfo.order.guest : orderInfo.residentName }
          </div> 
        <div style={{marginTop: "5px", paddingY: "5px", fontSize: "10px"}}>
          {`Order Taken: ${new Date().toLocaleString('en-US', DIRECT_PRINT_DATE_OPTIONS)}, by ${currentUserData?.firstName || ""} ${currentUserData?.lastName || ""}`}
        </div>
      </div>
    );
  }

  return (
    <> {
      isLoading ? (
        <EditSkeleton />
      )
      : 
      (
        <div className="paperContent customScrollbar">
          <Card className="overCard">
            <CardContent sx={{padding: ".5rem .75rem .5rem .75rem !important", display: "flex", flexWrap: "wrap", backgroundColor: "#3e6281"}}>
              <Typography 
                variant="h5"
                sx={{
                  color: "white !important", 
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}>
                {menuInfo && menuInfo.residentName}
              </Typography>
              <Typography 
                variant="subtitle2"
                sx={{
                  color: "white !important",
                  display: "inline",
                  marginLeft: "auto",
                  marginRight: "1rem",
                  fontWeight: "bold",
                  alignSelf: "end"
                }}
                >
                {menuInfo && menuInfo.roomBed?.split("/")?.[0] && ("ROOM: " + menuInfo.roomBed?.split("/")?.[0])}
              </Typography>
              <Typography 
                variant="subtitle2"
                sx={{
                  color: "white !important",
                  display: "inline",
                  fontWeight: "bold",
                  alignSelf: "end"
                }}
                >
                {menuInfo && menuInfo.roomBed?.split("/")?.[1] && ("BED: " + menuInfo.roomBed?.split("/")?.[1])}
              </Typography>
            </CardContent>
          </Card>
          <Card className="underCard">
            <CardContent sx={{display: "flex", flexWrap: "wrap"}}>
              <div style={{width: "100%", padding: ".5rem 0", borderBottom: "1px solid lightgray"}}>
                <span style={{margin: "0 2rem 0 .875rem"}} className="general-text">Dining Room</span>
                <Select 
                  sx={{marginRight: "2rem"}}
                  variant="standard" 
                  value={selectedDiningAreaId} 
                  onChange={(event) => selectDiningArea(event.target.value)}
                >
                  {renderDiningAreas()}
                </Select>

                <span style={{marginRight: "2rem"}} className="general-text">Table #</span>
                <Select variant="standard" value={selectedTableName} onChange={(event) => selectTable(event.target.value)}>
                  {renderTables()}
                </Select>
              </div>
              <Table>
                <TableBody>
                  {menuInfo &&
                    ((menuInfo.dietName !== 'N/A' && !!menuInfo.dietName) ||
                      (menuInfo.dietTextureName !== 'N/A' && !!menuInfo.dietTextureName) || 
                      (menuInfo.dietOther !== 'N/A' && !!menuInfo.dietOther)) && (
                        <TableRow>
                          <TableCell sx={{width: "15rem"}}>
                            <span className="general-text">Diet</span>
                          </TableCell>
                          <TableCell className="tableValue">
                            <span className="general-text">{menuInfo && menuInfo.dietName !== "N/A" && menuInfo.dietName}</span>
                            <span className="general-text">{checkForDietNameSeparator() && "  |  "}</span>
                            <span className="general-text">
                              {menuInfo.dietTextureName !== "" && menuInfo.dietTextureName !== "Regular" && `${menuInfo.dietTextureName}`}
                            </span>
                            <span className="general-text">{checkForDietOtherSeparator() && "  |  "}</span>
                            <span className="general-text">{menuInfo.dietOther !== "" && `${menuInfo.dietOther}`}</span>{" "}
                          </TableCell>
                        </TableRow>
                  )}
                  {menuInfo && menuInfo.liquidConsistency !== "N/A" && menuInfo.liquidConsistency !== "" && menuInfo.liquidConsistency !== null && (
                    <TableRow>
                      <TableCell>
                        <span className="general-text">Liquid Consistency</span>
                      </TableCell>
                      <TableCell className="tableValue">
                        <span className="general-text">{menuInfo && menuInfo.liquidConsistency !== "N/A" && menuInfo.liquidConsistency}</span>
                      </TableCell>
                    </TableRow>
                  )}
                  {menuInfo && menuInfo.fluidRestriction !== "N/A" && menuInfo.fluidRestriction !== "" && (
                    <TableRow>
                      <TableCell>
                        <span className="general-text">Fluid Restriction</span>
                      </TableCell>
                      <TableCell className="tableValue">
                        <span className="general-text">{menuInfo && menuInfo.fluidRestriction !== "N/A" && menuInfo.fluidRestriction}</span>
                      </TableCell>
                    </TableRow>
                  )}
                  {menuInfo && menuInfo.dislikes !== "N/A" && menuInfo.dislikes !== "" && (
                    <TableRow>
                      <TableCell>
                        <span className="general-text">Dislikes</span>
                      </TableCell>
                      <TableCell className="tableValue">
                        <span className="general-text">{menuInfo && menuInfo.dislikes !== "N/A" && menuInfo.dislikes}</span>
                      </TableCell>
                    </TableRow>
                  )}
                  {menuInfo && menuInfo.allergies !== "N/A" && menuInfo.allergies !== "" && (
                    <TableRow>
                      <TableCell>
                        <span className="general-text">Allergies</span>
                      </TableCell>
                      <TableCell className="tableValue">
                        <span className="general-text">{menuInfo && menuInfo.allergies !== "N/A" && menuInfo.allergies}</span>
                      </TableCell>
                    </TableRow>
                  )}
                  {!!(mealAddons && mealAddons.length) && (
                  <TableRow>
                    <TableCell>
                      <span className="general-text">Meals AddOn</span>
                    </TableCell>
                    <TableCell className="tableValue">
                      {mealAddons.map((item, idx) => {
                        return <div className="general-text" key={idx}>{item?.trim()}</div>;
                      })}
                    </TableCell>
                  </TableRow>
                )}
                  {menuInfo && menuInfo.adaptiveEquipment !== "N/A" && menuInfo.adaptiveEquipment !== "" && (
                    <TableRow>
                      <TableCell>
                        <span className="general-text">Adaptive Equipment</span>
                      </TableCell>
                      <TableCell className="tableValue">
                        <span className="general-text">{menuInfo && menuInfo.adaptiveEquipment !== "N/A" && menuInfo.adaptiveEquipment}</span>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>
                      <span className="general-text">Meal Price</span>
                    </TableCell>
                    <TableCell className="tableValue">
                      <span className="general-text">
                        {orderInfo && orderInfo.guestPrice !== "N/A" && "$" + Number(orderInfo.guestPrice).toFixed(2)}
                      </span>
                    </TableCell>
                </TableRow>
                {!!(menuInfo?.order?.residentId === 0 || menuInfo?.order?.guestTypeId > 0) && (
                  <TableRow>
                    <TableCell>
                      <span className="general-text">Guest Type</span>
                    </TableCell>
                    <TableCell className="tableValue">
                        <span className="general-text">
                          {!DEFAULT_EMPTY.includes(guestInfo?.selectedGuestType) && GUEST_TYPES[guestInfo?.selectedGuestType || 0]}
                        </span>
                    </TableCell>
                  </TableRow> 
                )}
                {menuInfo && menuInfo.notes && menuInfo.notes.length > 0 && (
                  <TableRow>
                    <TableCell>
                      <span className="general-text">Notes</span>
                    </TableCell>
                    <TableCell className="tableValue">
                      {menuInfo.notes.map((item, idx) => {
                        return <div className="general-text" key={idx}>{item}</div>;
                      })}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="orderCheckbox"
                          checked={declinedMeal}
                          onChange={handleDeclinedMealChange}
                        />
                      }
                      label={<span className="general-text tableValue">Declined Meal</span>}
                    />
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="orderCheckbox"
                          checked={outOfBuilding}
                          onChange={handleOutOfBuildingChange}
                        />
                      }
                      label={<span className="general-text tableValue">Out of Building</span>}
                    />
                  </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>

          {!outOfBuilding && !declinedMeal &&
            <>
              <Card className="overCard">
                <CardContent sx={{padding: ".25rem .75rem .25rem .75rem !important", display: "flex", alignItems: "center", justifyContent: "space-between", backgroundColor: "#d9d9d9"}}>
                  <Typography 
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}>
                    Daily Special
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{fontWeight: "bold", margin: ".25rem .75rem 0", color: "#FFF", backgroundColor: "var(--tealColor)"}}
                    onClick={() => setIsSelectAll((prev) => !prev)}
                  >
                    {isSelectAll ? "UNSELECT ALL" : "SELECT ALL"}
                  </Button>
                </CardContent>
              </Card>
              <Card className="underCard">
                <CardContent sx={{marginTop: "1rem"}}>
                  <Table>
                    <TableBody>
                      {renderMenuItems()}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </>
          }
          {!outOfBuilding && !declinedMeal &&
            <>
              <Card className="overCard">
                <CardContent sx={{padding: ".5rem .75rem .5rem .75rem !important", display: "flex", justifyContent: "space-between", backgroundColor: "#d9d9d9"}}>
                  <Typography 
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}>
                    Other Menu Options
                  </Typography>
                </CardContent>
              </Card>
              <Card className="underCard">
                <CardContent sx={{marginTop: "1rem"}}>
                  {renderAlternateItemsTabs()}
                </CardContent>
              </Card>
            </>
          }
          <>
            <Card className="overCard">
              <CardContent sx={{padding: ".5rem .75rem .5rem .75rem !important", display: "flex", justifyContent: "space-between", backgroundColor: "#d9d9d9"}}>
                <Typography variant="h5" sx={{textTransform: "uppercase", fontWeight: "bold"}}>
                  Note
                </Typography>
              </CardContent>
            </Card>
            <Card className="underCard">
              <CardContent sx={{padding: "2.5rem .25rem"}}>
                <TextField
                  id="textarea"
                  className="noteTextarea"
                  multiline
                  rows={3}
                  color="transparent"
                  fullWidth
                  sx={{width: "96%", marginX: "2%"}}
                  value={noteText}
                  // onChange fires too much to replace in object reference each time
                  // (overhead concern)
                  // store in state and replace before submit/hold
                  onChange={(event) => {
                    if (!event.target.value) {
                      setShowAdditionalCharges(false);
                      setAddlCharges(0.00);
                    }

                    setNoteText(event.target.value);
                  }}
                  />
                  <Grid container alignItems="center" justifyContent="space-between">
                    <Button 
                      disabled={!noteText}
                      variant="contained"
                      sx={{marginLeft: "2%", fontWeight: "bold", color: "#000", backgroundColor: "#FDB92E"}}
                      onClick={() => {
                        setShowAdditionalCharges((prev) => !prev);
                        setAddlCharges(0.00);
                      }}
                    >
                      Additional Charges
                    </Button>

                    {showAdditionalCharges && (
                      <MoneyInput
                        className="addlCharge"
                        value={addlCharges}
                        // onChange fires too much to replace in object reference each time
                        // (overhead concern)
                        // store in state and replace before submit/hold
                        onChange={(event) => setAddlCharges(event.target.value)}
                      />
                    )}
                  </Grid>
                  {addlCharges > 100 && (
                    <div style={{display: "flex", justifyContent: "flex-end", marginRight: "1rem"}}>
                      <p style={{color: "red", fontSize: ".75rem"}}>
                        Charge must be less than or equal to $100.00
                      </p>
                    </div>
                  )}
              </CardContent>
            </Card>
          </>
          <Grid 
            container 
            justifyContent="center" 
            alignItems="center"
            sx={{width: "100% !important", flex: 1}}>
            { (canHold || residentId === 0) && !fromSP && !outOfBuilding && !declinedMeal && (
              <Button
                variant="contained"
                sx={{fontWeight: "bold", marginRight: ".5rem", width: "calc(50% - 1rem)", backgroundColor: "var(--tealColor"}}
                onClick={() => handleSubmit("HOLD")}
              >
                Hold Ticket
              </Button>
            )}
            <Button
              variant="contained"
              color="#000"
              sx={{fontWeight: "bold", width: (fromSP || !canHold || outOfBuilding || declinedMeal) ? "calc(100% - 2.5rem)" : "calc(50% - 1rem)", backgroundColor: "#fdb92e"}}
              onClick={() => handleSubmit("SUBMIT")}
            >
              {fromSP ? "Submit / Reprint" : "Print Now"}
            </Button>
          </Grid>
          { infoModalOpen &&
            <InfoModal title={modalTitle} open={infoModalOpen} modalText={modalText} handleClose={handleModalClose} />
          }
          { subRecipeModalOpen && 
            <SubRecipeModal 
            open={subRecipeModalOpen} 
            selectedRecipe={currentSubRecipe} 
            currentSubRecipes={selectedSubRecipes} 
            specialNote={currentSubRecipeNote}
            handleClose={handleSubRecipeClose} /> 
          }
          { confirmModalOpen &&
            <OrderConfirmModal 
              open={confirmModalOpen}
              orderData={orderInfo}
              confirmType={confirmType}
              selectedSubRecipes={selectedSubRecipes}
              handleClose={handleConfirmClose}
            />
          }
          { reasonModalOpen &&
            <ReasonModal
              open={reasonModalOpen}
              title={reasonModalTitle}
              handleClose={handleReasonClose}
            />
          }
          <div id="printSection" style={{position: "absolute", top: "-9999px"}}> 
            {renderPrintContent()}
          </div>
        </div>
      )
    }
    </>
  )
}