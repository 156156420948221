import { Box, Button, Dialog, IconButton, MenuItem, Slide, Typography } from "@mui/material";
import React, { forwardRef, useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import BaseDatePicker from "../baseForm/BaseDatePicker";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import "./AutoStartModal.css";
import BaseInput from "../baseForm/BaseInput";
import { positiveNumber } from "utils/ValidationRegex";
import { REQUIRED_ERROR } from "utils/errorMessages";
import BaseSelect from "../baseForm/BaseSelect";
import { EditSkeleton } from "components/admin/admin.overlays";
import { useGetFacilityTableSideAutomationQuery, useUpdateFacilityTableSideAutomationMutation } from "store/apis/AutomationAPIs";
import CopyIcon from "@mui/icons-material/ContentCopy";
import CopyPopover from "./CopyPopover";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function AutoStartModal({ availableMeals, facilityId, open, handleClose }) {

  const [seasonOptions, setSeasonOptions] = useState([]);
  const [menuOptions, setMenuOptions] = useState([]);
  const [popoverAnchor, setPopoverAnchor] = useState(null);

  const popoverOpen = Boolean(popoverAnchor);
  const popoverId = popoverOpen ? 'copy-popover' : null;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = methods;

  const {
    data: automationData,
    isFetching: isFetchingAutomation,
    isSuccess: isSuccessAutomation,
  } = useGetFacilityTableSideAutomationQuery(facilityId, { skip: !facilityId });

  const [
    updateAutomation,
    { isSubmitting: isSubmittingAutomation, isSuccess: isSuccessAutomationUpdate },
  ] = useUpdateFacilityTableSideAutomationMutation();

  useEffect(() => {
    if (automationData && !isFetchingAutomation && isSuccessAutomation) {
      reset(automationData?.tableSideMenuAutomation);
      if (!automationData?.tableSideMenuAutomation?.menuStartDate) {
        setValue("menuStartDate", dayjs().format("YYYY-MM-DD"));
      }

          
      setValue('seasonId', automationData?.tableSideMenuAutomation?.seasonId);
      setValue('menuId', automationData?.tableSideMenuAutomation?.menuId);

      automationData?.tableSideMenuAutomation?.automationStartTimes?.map(
        (item) => {
          switch (item?.mealId) {
            case 1:
              setValue(
                `breakfast-${item?.dayOfTheWeek - 1}`,
                dayjs(item?.startTime).format("HH:mm")
              );
              break;
            case 2:
              setValue(
                `lunch-${item?.dayOfTheWeek - 1}`,
                dayjs(item?.startTime).format("HH:mm")
              );
              break;
            case 3:
              setValue(
                `dinner-${item?.dayOfTheWeek - 1}`,
                dayjs(item?.startTime).format("HH:mm")
              );
              break;
          }
        }
      );
    }
  }, [automationData, isFetchingAutomation, isSuccessAutomation]);

  useEffect(() => {
    if (!isSubmittingAutomation && isSuccessAutomationUpdate) {
      handleClose();
    }
  }, [isSubmittingAutomation, isSuccessAutomationUpdate]);

  const menuStartDate = watch("menuStartDate");

  useEffect(() => {

    if (availableMeals && automationData) {
      setSeasonOptions(() => availableMeals?.seasons?.map((season) => {
        return { id: season.id, label: season.name };
      }));
    
      setMenuOptions(() => availableMeals?.seasons?.find((season) => season.id === automationData?.tableSideMenuAutomation?.seasonId)?.menus?.map((menu) => {
        return { id: menu.id, label: menu.name};
      }));

    }

  }, [availableMeals, automationData]);

  const handleSeasonChange = (season) => {
    const newSeason = availableMeals?.seasons?.find((s) => s.id === season?.id);
    const newSeasonMenu = newSeason?.menus?.find((menu) => menu?.id === getValues("menuId") ||  menu?.id === automationData?.tableSideMenuAutomation?.menuId);

    setMenuOptions(() => newSeason?.menus?.map((menu) => {
      return { id: menu.id, label: menu.name};
    }));

    setValue('menuId', newSeasonMenu ? newSeasonMenu?.id : null);
    setValue('seasonId', season.id);
  }

  const renderMenus = () => {
    const menuList = [];

    if (getValues()?.season) {
      getValues()?.season.menus?.forEach((menu) => {
        menuList.push(
          <MenuItem key={menu.id} value={menu}>{menu.name}</MenuItem>
        );
      });
    }

    return menuList;
  }

  const onSubmit = (data) => {
    data["facilityId"] = facilityId;
    data["saveData"] = "";

    for (let i = 0; i < 7; i++) {
      let breakfast = data[`breakfast-${i}`];
      let lunch = data[`lunch-${i}`];
      let dinner = data[`dinner-${i}`];
      
      if (!dayjs(breakfast).isValid()) {
        breakfast = dayjs(`2024-05-15T${breakfast}`);
      }

      if (!dayjs(lunch).isValid()) {
        lunch = dayjs(`2024-05-15T${lunch}`);
      }

      if (!dayjs(dinner).isValid()) {
        dinner = dayjs(`2024-05-15T${dinner}`);
      }

      data.saveData += `${i + 1}_1=${dayjs(breakfast).format("HH:mm")};`;
      data.saveData += `${i + 1}_2=${dayjs(lunch).format("HH:mm")};`;
      data.saveData += `${i + 1}_3=${dayjs(dinner).format("HH:mm")};`;
    }

    updateAutomation({
      ...data,
      dateEndTemporary: dayjs(data?.dateEndTemporary).isValid()
        ? dayjs(data?.dateEndTemporary).format("YYYY-MM-DD")
        : null,
    });    
  }

  const handleCopyClick = (event) => {
    setPopoverAnchor(event.currentTarget);
  }

  const handlePopoverClose = (data = null) => {
    setPopoverAnchor(null);
    if (data) {
      Object.keys(data).forEach((key) => {
        if (!['selectAll', '0'].includes(key) && data[key]) {
          setValue(`breakfast-${key}`, getValues('breakfast-0'))
          setValue(`lunch-${key}`, getValues('lunch-0'));
          setValue(`dinner-${key}`, getValues('dinner-0'));
        }
      })
    }
  }

  return (
    <Dialog
      id="autoDialog"
      open={open}
      TransitionComponent={Transition}
    >
      <>
        {isFetchingAutomation ? 
          (
            <EditSkeleton />
          ) :
          (

            <Box 
              component="div"
              sx={{
                padding: "2rem", 
                display: "flex", 
                flexWrap: "wrap",
                flex: "1 0 100%", 
                justifyContent: "flex-start", 
                alignContent: "flex-start"
              }}
            >
              <Typography gutterBottom variant="h5" fontWeight="bold" width="100%">
                Automatic Meal Queues
              </Typography>
              <Typography
                variant="p"
                component={"p"}
                align="left"
                sx={{
                  fontWeight: "600"
                }}>
                {`Timezone setup is ${automationData?.tableSideMenuAutomation?.timeZoneName}`}
              </Typography>

              <Grid container spacing={2} sx={{width: "100%", margin: "1rem 0 2rem", alignItems: "end"}}>
                <Grid size={{xs: 4, md: 3}} sx={{alignSelf: "start"}}>
                  <BaseDatePicker
                    name="menuStartDate"
                    label="Menu Start Date"
                    id="menuStartDate"
                    defaultValue={dayjs().format("YYYY-MM-DD")}
                    control={control}
                    errors={errors}
                    slotProps={{
                      field: { clearable: false }
                    }}
                    validationProps={{ required: REQUIRED_ERROR("Menu Start Date") }}
                  />
                </Grid>
                <Grid size={{xs: 4, md: 3}} sx={{alignSelf: "start", paddingTop: ".5rem"}}>
                  <BaseInput
                    name="menuStartDay"
                    label="Menu Start Day"
                    id="menuStartDay"
                    type="number"
                    defaultValue={0}
                    control={control}
                    errors={errors}
                    validationProps={{
                      required: REQUIRED_ERROR("Menu Start Day"),
                      maxLength: {
                        value: 3,
                        message: "Please enter 3 numbers or less",
                      },
                      pattern: {
                        value: positiveNumber,
                        message: "Please enter positive number",
                      },
                    }}
                  />
                </Grid>
                <Grid size={{xs: 4, md: 3}} sx={{alignSelf: "start", paddingTop: ".5rem"}}>
                  <BaseInput
                    name="createDaysAhead"
                    label="Days Ahead to Create"
                    id="createDaysAhead"
                    type="number"
                    control={control}
                    min={1}
                    defaultValue={1}
                    errors={errors}
                    validationProps={{
                      required: REQUIRED_ERROR("Days Ahead to Create"),
                      maxLength: {
                        value: 3,
                        message: "Please enter 3 numbers or less",
                      },
                      pattern: {
                        value: positiveNumber,
                        message: "Please enter positive number",
                      },
                    }}
                  />
                </Grid>
                <Grid size={{xs: 6, md: 4}} sx={{alignSelf: "start"}}>
                  <BaseSelect
                    name="seasonId"
                    id="seasonId"
                    label="Season"
                    control={control}
                    options={seasonOptions || []}
                    onChange={(event, value) => handleSeasonChange(value)}
                    validationProps={{
                      required: REQUIRED_ERROR("Season"),
                    }}
                    slotProps={{
                      field: { clearable: false }
                    }}
                    errors={errors}
                  />
                </Grid>
                <Grid size={{xs: 6, md: 5}} sx={{alignSelf: "start"}}>
                  <BaseSelect
                    name="menuId"
                    id="menuId"
                    label="Menu"
                    control={control}
                    options={menuOptions || []}
                    validationProps={{
                      required: REQUIRED_ERROR("Menu"),
                    }}
                    slotProps={{
                      field: { clearable: false }
                    }}
                    errors={errors}
                  >
                    {renderMenus()}
                  </BaseSelect>
                </Grid>
                <Grid size={{xs: 6, md: 4}}>
                  {/* Placeholder for dining area later */}
                </Grid>
              </Grid>
              
              <Grid container spacing={2} sx={{width: "100%", marginBottom: "1rem", alignItems: "end"}}>
                {Array.from({ length: 7 }).map((it, index) => (
                  <Grid size={{xs: 6, md: 3}} key={index}>
                    <Box
                      sx={{
                        borderRadius: "10px",
                        border: 1,
                        borderColor: "black",
                        p: 2
                      }}>
                      <Box width="100%" display="flex" flexWrap="nowrap" justifyContent="center" alignItems="center">
                        <Typography align="center" sx={{
                          fontWeight: "700",
                          top: "50%",
                          left: "50%",
                          position: index === 0 ? "relative" : "static",
                          transform: index === 0 ? "translate(-50%)" : ""
                        }}>
                          {dayjs(menuStartDate).add(index, "days").format("dddd")}
                        </Typography>
                        {index === 0 && 
                          <>
                            <IconButton 
                              aria-describedby={popoverId}
                              variant="contained"
                              sx={{
                                display: "inline-flex",
                                backgroundColor: "var(--orangeColor)",
                                ":hover": {backgroundColor: "#B28920"}, 
                                " > *": {fontSize: ".875rem"},
                                borderRadius: ".25rem !important",
                                padding: ".25rem",
                                marginLeft: "auto"
                              }}
                              onClick={handleCopyClick}
                            >
                              <CopyIcon />
                            </IconButton>
                            <CopyPopover
                              id={popoverId}
                              open={popoverOpen}
                              popoverAnchor={popoverAnchor}
                              handleClose={handlePopoverClose}
                              menuStartDate={menuStartDate}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right"
                              }}
                            />
                          </>
                        }
                      </Box>
                      <Grid container spacing={2} sx={{
                        marginTop: "2px"
                      }}>
                        <Grid md={12}>
                          <BaseDatePicker
                            name={"breakfast-" + index}
                            label="Breakfast"
                            id={"breakfast-" + index}
                            defaultValue={"06:30"}
                            type="time"
                            timeFormat="HH:mm"
                            minutesStep={15}
                            control={control}
                            errors={errors}
                            validationProps={{
                              required: REQUIRED_ERROR("Breakfast Time"),
                            }}
                            slotProps={{
                              field: { clearable: false }
                            }}
                          />
                        </Grid>
                        <Grid md={12}>
                          <BaseDatePicker
                            name={"lunch-" + index}
                            label="Lunch"
                            id={"lunch-" + index}
                            type="time"
                            timeFormat="HH:mm"
                            defaultValue={"11:00"}
                            minutesStep={15}
                            control={control}
                            errors={errors}
                            validationProps={{
                              required: REQUIRED_ERROR("Lunch Time"),
                            }}
                            slotProps={{
                              field: { clearable: false }
                            }}
                          />
                        </Grid>
                        <Grid md={12}>
                          <BaseDatePicker
                            name={"dinner-" + index}
                            label="Dinner"
                            id={"dinner-" + index}
                            type="time"
                            timeFormat="HH:mm"
                            defaultValue={"16:30"}
                            minutesStep={15}
                            control={control}
                            errors={errors}
                            validationProps={{
                              required: REQUIRED_ERROR("Dinner Time"),
                            }}
                            slotProps={{
                              field: { clearable: false }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              
              <Box width="100%" display="flex" justifyContent="flex-end">
                <Button sx={{backgroundColor: "var(--orangeColor)", color: "#000", marginRight: ".5rem"}} variant="contained" onClick={handleClose}>Cancel</Button>
                <Button sx={{width: "fit-content"}} variant="contained" onClick={handleSubmit(onSubmit)}>
                  Save
                </Button>
              </Box>
            </Box>
          )
        }
      </>
    </Dialog>
  )
}