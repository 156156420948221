import { Box, Button, Popover, Typography } from "@mui/material";
import React, { useCallback } from "react";
import BaseCheckBox from "../baseForm/BaseCheckBox";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";

export default function CopyPopover({id, open, popoverAnchor, menuStartDate, handleClose}) {

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    handleClose(data);
    reset();
  }

  const dayCheckboxes = watch(["0", "1", "2", "3", "4", "5", "6"]);

  const isSelectAll = dayCheckboxes.every(Boolean);
  const someChecked = dayCheckboxes.some(Boolean);

  const handleSelectAll = useCallback(
    (event) => {
      const checked = event.target.checked;
      ["0", "1", "2", "3", "4", "5", "6"].forEach(
        (day) => {
          setValue(day, checked);
        }
      );
    },
    [setValue]
  );

  const handleClickOut = () => {
    handleClose(null);
    reset();
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={popoverAnchor}
      onClose={() => handleClickOut()}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
    >
      <Box id="copyBox" sx={{padding: ".5rem", width: "min-content", " * > p": {whiteSpace: "pre"}}}>
        <Typography variant="subtitle1">Copy Times To:</Typography>
        <BaseCheckBox
          control={control}
          label="Select All"
          name="selectAll"
          errors={errors}
          checked={isSelectAll}
          indeterminate={someChecked && !isSelectAll}
          onClick={handleSelectAll}
        />  
        {Array.from({ length: 7 }).map((it, index) => (
          <BaseCheckBox
            key={index}
            control={control}
            label={`${dayjs(menuStartDate).add(index, "days").format("dddd")}`}
            name={`${index}`}
            errors={errors}
          />  
        ))}
      </Box>
      <Box display="flex" justifyContent="center" width="100%" sx={{padding: ".25rem"}}>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}> 
          Submit
        </Button>
      </Box>
    </Popover>
  );
}