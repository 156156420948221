import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  facilityName: null,
  facilityId: null
}

export const userSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setFacilityName: (state, action) => {
      state.facilityName = action.payload;
    },
    setFacilityId: (state, action) => {
      state.facilityId = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setFacilityName, setFacilityId } = userSlice.actions;

export const selectFacilityId = (state) => state?.facilityId;


export default userSlice.reducer