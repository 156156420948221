import { Box, Button, Dialog, Slide, Typography } from "@mui/material";
import React, { forwardRef, useEffect } from "react";
import { useDeleteMealMutation } from "store/apis/MealsApi";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteMealModal({ open, order, handleClose }) {
  const [deleteMeal, {isSubmitting: isDeletingMeal, isSuccess: isSuccessDeleteMeal}] = useDeleteMealMutation();

  const onSubmit = () => {
    deleteMeal(order.id);
  }

  useEffect(() => {
    if (!isDeletingMeal && isSuccessDeleteMeal) {
      handleClose();
    }
  }, [isDeletingMeal, isSuccessDeleteMeal])

  let message = "";

  if(order?.ordersTaken > 1) {
    message = `***There are ${order.ordersTaken } ordered meals***\nThese would be deleted.`;
  } else {
    message = `***There is ${order.ordersTaken } ordered meal***\nThis would be deleted.`;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      >
      <Box component="div" sx={{display: "flex", flexWrap: "wrap", flex: "1 0 100%", justifyContent: "center", width: "24rem"}}>
        <Typography 
          variant="h5" 
          textAlign="center" 
          sx={{ 
            margin: "0 0 .5rem", 
            padding: ".25rem", 
            display: "block", 
            width: "100%", 
            backgroundColor: "var(--redColor)",
            color: "#fff"
          }}>
            Are you sure you want to delete this queued meal?
        </Typography>
        <Typography variant="subtitle1" textAlign="center" sx={{whiteSpace: "pre", marginBottom: "1rem", width: "100%"}}>
          {message}
        </Typography>
        <Box width="100%" display="flex" justifyContent="center" padding="0 1rem 1rem 0">
          <Button sx={{backgroundColor: "var(--orangeColor)", color: "#000", marginRight: ".5rem"}} variant="contained" onClick={handleClose}>Cancel</Button>
          <Button sx={{width: "fit-content", backgroundColor: "var(--redColor)"}} variant="contained" onClick={() => onSubmit()}>
            Delete
          </Button>
      </Box>
      </Box>
    </Dialog>
  );
}